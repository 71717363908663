const typography = {
  fontFamily: ["Manrope", "sans-serif"].join(", "),
  h1: {
    fontWeight: 700,
    fontSize: "24px",
    letterSpacing: "-0.6px",
  },
  h2: {
    fontWeight: 500,
    fontSize: "18px",
    letterSpacing: "0px",
    lineHeight: "30px",
  },
  button: {
    fontWeight: 700,
    fontSize: "14px",
    letterSpacing: "0px",
    lineHeight: "19px",
  },
  overline: {
    fontWeight: 500,
  },
};

export default typography;
