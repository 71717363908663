import React from "react";
import { Box, Typography } from "@mui/material";
import { ReactComponent as Call } from "../../assets/svgs/phone-call.svg";
import { ReactComponent as SmartPhone } from "../../assets/svgs/smartphone.svg";
import { ReactComponent as Mail } from "../../assets/svgs/mail.svg";
import { ReactComponent as Link } from "../../assets/svgs/link.svg";
import { ReactComponent as Archive } from "../../assets/svgs/archive.svg";
import { omit } from "lodash";

function getLogo(key) {
  let logo;
  switch (key) {
    case "phone_number":
      logo = <Call />;
      break;
    case "message_number":
      logo = <SmartPhone />;
      break;
    case "whatsapp":
      logo = <SmartPhone />;
      break;
    case "email":
      logo = <Mail />;
      break;
    case "address":
      logo = <Archive />;
      break;
    case "link":
      logo = <Link />;
      break;
    default:
      logo = <Archive />;
  }
  return logo;
}

function getValue(key, val) {
  let text;
  switch (key) {
    case "phone_number":
      text = `+${val?.calling_code + "-" + val?.mobile_number}`;
      break;
    case "message_number":
      text = `+${val?.calling_code + "-" + val?.mobile_number}`;
      break;
    case "whatsapp":
      text = `+${val?.calling_code + "-" + val?.mobile_number}`;
      break;
    case "email":
      text = `${Object.values(val).join("")}`;
      break;
    case "address":
      text = `${Object.values(val).join(", ")}`;
      break;
    case "link":
      text = `${Object.values(val).join("")}`;
      break;
    default:
      text = `${Object.values(val).join("")}`;
  }
  return text;
}

function getLink(key, val) {
  let text;
  switch (key) {
    case "phone_number":
      text = `tel:+${val?.calling_code + "-" + val?.mobile_number}`;
      break;
    case "message_number":
      text = `tel:+${val?.calling_code + "-" + val?.mobile_number}`;
      break;
    case "whatsapp":
      text = `tel:+${val?.calling_code + "-" + val?.mobile_number}`;
      break;
    case "email":
      text = `mailto:${Object.values(val).join("")}`;
      break;
    case "address":
      text = `#`;
      break;
    case "link":
      text = `${Object.values(val).join("")}`;
      break;
    default:
      text = `#`;
  }
  return text;
}

const ContactInfoCard = ({ data }) => {
  const key = data?.key;
  const value = data?.values;
  const textValue = data?.link;
  const refactoredData =
    value && omit(value, ["is_default", "country_code", "is_enabled", "type"]);

  return (
    <>
      {(refactoredData || textValue) && (
        <Box sx={{ display: "flex", p: "4px 8px 14px", alignItems: "center" }}>
          <Box
            sx={{
              mr: "32px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {getLogo(key || "link")}
          </Box>
          <Typography
            textOverflow="ellipsis"
            fontSize={{ xs: "14px", overflow: "hidden" }}
          >
            <a
              target={textValue ? "_blank" : "_self"}
              rel="noreferrer"
              style={{
                textDecoration: "none",
                textTransform: "none",
                color: "#3F4451",
              }}
              href={
                refactoredData
                  ? getLink(key, refactoredData)
                  : `//${textValue?.replace(/(^\w+:|^)\/\//, "")}` || "#"
              }
            >
              {refactoredData ? getValue(key, refactoredData) : textValue}
            </a>
          </Typography>
        </Box>
      )}
    </>
  );
};

export default ContactInfoCard;
