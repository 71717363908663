import { createTheme } from "@mui/material/styles";
import typography from "./typography";
import { colors } from "@mui/material";

const theme = createTheme({
  palette: {
    background: {
      default: "#FFFFFF",
      paper: colors.common.white,
    },
    primary: {
      main: "#6149CD",
      secondary: "#6B70DF",
    },
    error: {
      main: "#FF7A7A",
    },
    text: {
      primary: "#3F4451",
      secondary: "#7A86A1",
    },
  },
  typography,
});

export default theme;
