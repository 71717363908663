import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import AdbIcon from "@mui/icons-material/Adb";
import { ReactComponent as Liima } from "../../assets/svgs/liima-color.svg";
import Button from "../core/Button";
import { useNavigate } from "react-router-dom";

const ResponsiveAppBar = () => {
  const navigate = useNavigate();
  return (
    <AppBar
      position="static"
      color="transparent"
      sx={{ boxShadow: "none", mt: "48px" }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexGrow: { xs: 1, md: 0 },
            }}
          >
            <Liima />
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}></Box>

          <Box
            sx={{
              flexGrow: 0,
              width: "100%",
              maxWidth: "166px",
              display: { xs: "none", md: "flex" },
            }}
          >
            <Button
              variant="outlined"
              height={"37px"}
              fullWidth
              onClick={() =>
                window.open(
                  process.env.REACT_APP_CUSTOMER_APP_URL + "/signup",
                  "_blank"
                )
              }
            >
              GET YOUR CARD
            </Button>
          </Box>
          <Box
            sx={{
              flexGrow: 0,
              width: "100%",
              maxWidth: "112px",
              ml: "24px",
              display: { xs: "none", md: "flex" },
            }}
          >
            <Button
              variant="contained"
              height={"37px"}
              fullWidth
              onClick={() =>
                window.open(process.env.REACT_APP_CUSTOMER_APP_URL, "_blank")
              }
            >
              LOG IN
            </Button>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default ResponsiveAppBar;
