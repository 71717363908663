import React from "react";
import { Box } from "@mui/material";
import { ReactComponent as Liima } from "../../assets/svgs/liima-color.svg";

const Loading = () => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "100vh",
      }}
    >
      <Liima />
    </Box>
  );
};

export default Loading;
