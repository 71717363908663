import * as React from "react";
import Appbar from "../Appbar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";

export default function SignIn(props) {
  return (
    <Box
      component="main"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "100vh",
        maxHeight: "100vh",
        width: "100%",
        padding: 0,
      }}
    >
      <Appbar />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          maxWidth: "1100px",
          boxShadow: "4px 22px 54px #695F971A",
          borderRadius: "8px",
          padding: { xs: "17px 15px", md: "25px 50px" },
          pb: "40px",
          mt: { xs: "24px", md: "56px" },
          mx: { xs: "15px", md: 0 },
        }}
      >
        {props.children}
      </Box>
    </Box>
  );
}
