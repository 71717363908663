import React from "react";
import ErrorLayout from "../components/layouts/ErrorPageLayout";
import { Box, Typography } from "@mui/material";
import { ReactComponent as PageNotFound } from "../assets/svgs/Robot.svg";

const Login = () => {
  return (
    <ErrorLayout>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
        }}
      >
        <PageNotFound />
        <Typography
          fontSize="30px"
          lineHeight="60px"
          textAlign="center"
          fontWeight="700"
          sx={{ mt: "25px" }}
        >
          Suspicious Activity Detected!
        </Typography>
        <Typography
          fontSize="16px"
          lineHeight="22px"
          textAlign="center"
          sx={{ color: "#8E8E8E", maxWidth: "400px" }}
        >
          The security system has detected suspicious activity on your connection. Please try again.
        </Typography>
      </Box>
    </ErrorLayout>
  );
};

export default Login;
