import axios from "axios";

const API = axios.create();

API.interceptors.request.use(
  (config) => {
    config.baseURL = "/api/v1";
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

API.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    return Promise.reject(error);
  }
);

export default API;
