import API from "./api";

export const getProfile = async (id, data) => {
  try {
    const response = await API.get(`/public/profile_name/${id}`, {
      params: data
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const saveProfile = async (id) => {
  try {
    const response = await API.post(`/profile/save_contact/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};