import React from "react";
import { Box } from "@mui/material";
import { ReactComponent as TwitterLogo } from "../../assets/svgs/twitter.svg";
import { ReactComponent as Facebook } from "../../assets/svgs/fb.svg";
import { ReactComponent as Instagram } from "../../assets/svgs/instagram.svg";
import { ReactComponent as Linkedin } from "../../assets/svgs/linkedin.svg";
import { ReactComponent as Tiktok } from "../../assets/svgs/tiktok.svg";
import { ReactComponent as Youtube } from "../../assets/svgs/youtube.svg";
import { ReactComponent as Link } from "../../assets/svgs/link.svg";
import { omit } from "lodash";
import { ReactComponent as Xing } from "../../assets/svgs/xing.svg";
import { ReactComponent as GooglePay } from "../../assets/svgs/google-pay.svg";
import { ReactComponent as ApplePay } from "../../assets/svgs/apple-pay.svg";
import { ReactComponent as Paypal } from "../../assets/svgs/paypal-pay.svg";

function getLogo(key) {
  let logo;
  switch (key) {
    case "facebook":
      logo = <Facebook />;
      break;
    case "youtube":
      logo = <Youtube />;
      break;
    case "linkedin":
      logo = <Linkedin />;
      break;
    case "instagram":
      logo = <Instagram />;
      break;
    case "twitter":
      logo = <TwitterLogo />;
      break;
    case "tiktok":
      logo = <Tiktok />;
      break;
    case "xing":
      logo = <Xing />;
      break;
    case "google_pay":
      logo = <GooglePay />;
      break;
    case "apple_pay":
      logo = <ApplePay />;
      break;
    case "paypal":
      logo = <Paypal />;
      break;
    default:
      logo = <Link />;
  }
  return logo;
}

const SocialNetworkButton = ({ data }) => {
  const key = data?.key;
  const value = data?.values;
  const refactoredData = value && omit(value, ["is_default"]);
  return (
    <Box
      onClick={() =>
        window.open(`//${refactoredData?.link?.replace(/(^\w+:|^)\/\//, "")}`, "_blank")
      }
      sx={{
        height: "40px",
        width: "40px",
        backgroundColor: "#FFFFFF",
        boxShadow: "0px 2px 6px #00000015",
        borderRadius: "9px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        p: "10px",
      }}
    >
      {getLogo(key)}
    </Box>
  );
};

export default SocialNetworkButton;
