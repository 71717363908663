import React, { useState, useEffect } from "react";
import PublicProfileLayout from "../components/layouts/PublicProfileLayout";
import ErrorLayout from "../components/layouts/ErrorPageLayout";
import { Box, Typography } from "@mui/material";
import Button from "../components/core/Button";
import SocialNetworkButton from "../components/core/SocialNetworkButton";
import PaymentsButton from "../components/core/PaymentsButton";
import { getProfile, saveProfile } from "../services/profileServices";
import { useParams } from "react-router-dom";
import Loading from "../components/core/Loading";
import ContactInfoCard from "../components/core/ContactInfoCard";
import profilePic from "../assets/images/profile-pic.png";
import Vcard from "../utils/vCard";
import { ReactComponent as ProfileNotFound } from "../assets/svgs/profile-not-found.svg";
import { useNavigate } from "react-router-dom";
// import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

const Login = () => {
  const { id, company_slug } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState(null);
  const [profile, setProfile] = useState(null);

  // const { executeRecaptcha } = useGoogleReCaptcha();

  const toBase64String = (url) =>
    fetch(url)
      .then((response) => response.blob())
      .then(
        (blob) =>
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
          })
      );

  const saveContact = async (data) => {
    const vCard = Vcard();
    const phoneNumberObj = data?.contacts?.filter(
      (obj) => obj["key"] === "phone_number"
    );
    const msgNumberObj = data?.contacts?.filter(
      (obj) => obj["key"] === "message_number"
    );
    const addressObj = data?.contacts?.filter(
      (obj) => obj["key"] === "address"
    );
    let image = "";
    data?.avatar &&
      (await toBase64String(data?.avatar).then((dataUrl) => {
        image = dataUrl.replace("data:", "").replace(/^.+,/, "");
      }));
    const emailObj = data?.contacts?.filter((obj) => obj["key"] === "email");
    const socialMedia = data?.social_media;
    const otherLinks = data?.other_links?.map((val) => ({
      key: "website",
      label: val?.label || "Website",
      values: val,
    }));
    vCard.firstName = data?.first_name;
    vCard.lastName = data?.last_name;
    vCard.organization = data?.company_name;
    image && vCard.photo.embedFromString(image, "PNG");
    vCard.title = data?.tagline;
    phoneNumberObj && vCard.tels.push(...phoneNumberObj);
    msgNumberObj && vCard.tels.push(...msgNumberObj);
    emailObj && vCard.emails.push(...emailObj);
    socialMedia && vCard.links.push(...socialMedia);
    otherLinks && vCard.links.push(...otherLinks);
    addressObj && vCard.addresses.push(...addressObj);

    vCard.note = "Get your smart business card from liima.co";

    //save to file
    vCard.saveToFile("liima-" + profile?.first_name);

    // For Stats
    await saveProfile(profile?.id);
  };

  useEffect(() => {
    const getProfileData = async (userId) => {
      setError("");
      try {
        // if (!executeRecaptcha) {
        //   // console.log("Execute recaptcha not yet available");
        //   return;
        // }

        // const token = await executeRecaptcha("Page");

        // let data = { recaptcha_token: token };
        let data;

        if (company_slug) {
          if (company_slug === "dry+cut") {
            navigate(`/cut+dry/${userId}`)
            return;
          }

          data = { ...data, company_slug: company_slug.toLowerCase() }
        }
        
        const response = await getProfile(userId, data);
        if (
          response?.data?.public_name !== null &&
          response?.data?.public_name !== id
        ) {
          navigate(company_slug ? `/${company_slug}/${response?.data?.public_name}`: `/${response?.data?.public_name}`);
        }
        setProfile(response.data.profile);
        setLoading(false);
      } catch (err) {
        if(err?.response?.data?.code === 403){
          navigate("/suspicious");
        }

        if (err?.response?.data?.message) {
          setError(err.response.data.message);
        } else {
          setError("An error occurred! please try again later.");
        }
        setLoading(false);
      }
    };
    id && getProfileData(id);
  }, [id, company_slug, navigate]);

  if (loading) {
    return <Loading />;
  }

  return profile ? (
    <PublicProfileLayout>
      <>
        <Box
          sx={{
            width: "100%",
            height: "200px",
            borderRadius: "20px",
            boxShadow: "4px 22px 54px #695F9714",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              width: "180px",
              height: "200px",
              borderRadius: "8px 0px 0px 8px",
              overflow: "hidden",
            }}
          >
            <img
              width="100%"
              height="100%"
              src={profile?.avatar || profilePic}
              alt="profile"
              style={{ objectPosition: "center", objectFit: "cover" }}
            />
          </Box>
          <Box sx={{ ml: "35px", flexGrow: 1 }}>
            <Typography
              fontSize={{ xs: "18px", md: "26px" }}
              lineHeight={{ xs: "22px", md: "24px" }}
              fontWeight="700"
            >
              {profile?.first_name + " " + profile?.last_name}
            </Typography>
            <Typography
              fontSize={{ xs: "10px", md: "16px" }}
              lineHeight={{ xs: "16px", md: "22px" }}
              fontWeight="200"
            >
              {profile?.tagline}
            </Typography>
          </Box>
          <Box
            sx={{
              mr: "30px",
              width: "100%",
              maxWidth: "206px",
              display: { xs: "none", md: "block" },
            }}
          >
            <Box sx={{ flexGrow: 0, mb: "24px" }}>
              <Button
                variant="contained"
                height={"44px"}
                fullWidth
                onClick={() => saveContact(profile)}
              >
                Save Contact
              </Button>
            </Box>
            <Box sx={{ flexGrow: 0 }}>
              <Button
                variant="outlined"
                height={"44px"}
                fullWidth
                onClick={() =>
                  window.open(
                    process.env.REACT_APP_CUSTOMER_APP_URL + "/signup",
                    "_blank"
                  )
                }
              >
                Exchange Contact
              </Button>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: { xs: "block", md: "none" },
            mt: "24px",
          }}
        >
          <Box sx={{ flexGrow: 0, mb: "18px" }}>
            <Button
              variant="contained"
              height={"44px"}
              fullWidth
              onClick={() => saveContact(profile)}
            >
              SAVE CONTACT
            </Button>
          </Box>
          <Box sx={{ flexGrow: 0 }}>
            <Button
              variant="outlined"
              height={"44px"}
              fullWidth
              onClick={() =>
                window.open(
                  process.env.REACT_APP_CUSTOMER_APP_URL + "/signup",
                  "_blank"
                )
              }
            >
              GET YOUR CARD
            </Button>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            mt: { xs: "30px", md: "45px" },
            px: { xs: 0, md: "44px" },
            justifyContent: "space-between",
            flexDirection: { xs: "column", md: "row" },
          }}
        >
          <Box sx={{ width: "100%", maxWidth: "388px" }}>
            {profile?.bio && (
              <Box sx={{ mb: { xs: "24px", md: "18px" } }}>
                <Typography variant="h2" fontSize={{ xs: "16px", md: "18px" }}>
                  About
                </Typography>
                <Typography
                  fontSize={{ xs: "12px", md: "14px" }}
                  lineHeight={{ xs: "20px", md: "22px" }}
                  fontWeight="300"
                  sx={{
                    color: "#707070",
                    mt: "12px",
                    mb: { xs: "26px", md: "20px" },
                  }}
                >
                  {profile?.bio}
                </Typography>
                <hr
                  style={{
                    border: 0,
                    height: 0,
                    borderTop: "1px solid rgba(0, 0, 0, 0.1)",
                    borderBottom: "1px solid rgba(255, 255, 255, 0.3)",
                  }}
                />
              </Box>
            )}
            <Box>
              <Typography variant="h2" fontSize={{ xs: "16px", md: "18px" }}>
                Social network
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  gap: "24px",
                  mt: "12px",
                  flexWrap: "wrap",
                  mb: { xs: "26px", md: 0 },
                }}
              >
                {profile?.social_media?.map((data, index) => (
                  <SocialNetworkButton key={index} data={data} />
                ))}
              </Box>
            </Box>
            {profile?.payments && (
              <Box>
                <Typography variant="h2" fontSize={{ xs: "16px", md: "18px" }}>
                  Payments
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    gap: "24px",
                    mt: "12px",
                    flexWrap: "wrap",
                    mb: { xs: "26px", md: 0 },
                  }}
                >
                  {profile?.payments?.map((data, index) => (
                    <PaymentsButton key={index} data={data} />
                  ))}
                </Box>
              </Box>
            )}
          </Box>

          <Box
            sx={{
              width: 0,
              borderRight: "0.1px solid rgba(0, 0, 0, 0.1)",
              display: { xs: "none", md: "flex" },
            }}
          ></Box>

          <Box sx={{ display: { sx: "flex", md: "none" }, mb: "25px" }}>
            <hr
              style={{
                border: 0,
                height: 0,
                borderTop: "1px solid rgba(0, 0, 0, 0.1)",
                borderBottom: "1px solid rgba(255, 255, 255, 0.3)",
              }}
            />
          </Box>

          <Box sx={{ width: "100%", maxWidth: "352px" }}>
            <Typography fontSize={{ xs: "16px", md: "18px" }} variant="h2">
              Contact info
            </Typography>
            <Box sx={{ mt: "20px" }}>
              {profile?.contacts?.map((data) => (
                <ContactInfoCard data={data} />
              ))}
              {profile?.other_links?.map((data, index) => (
                <ContactInfoCard key={index} data={data} />
              ))}
            </Box>
          </Box>
        </Box>
      </>
    </PublicProfileLayout>
  ) : (
    <ErrorLayout>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
        }}
      >
        <ProfileNotFound />
        <Typography
          fontSize="30px"
          lineHeight="60px"
          textAlign="center"
          fontWeight="700"
          sx={{ mt: "25px" }}
        >
          Oops!
        </Typography>
        <Typography
          fontSize="16px"
          lineHeight="22px"
          textAlign="center"
          sx={{ color: "#8E8E8E" }}
        >
          Profile not found, Please check and try again.
        </Typography>
      </Box>
    </ErrorLayout>
  );
};

export default Login;
