import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage from "./pages/PublicProfile";
import Notfound from "./pages/NotFound";
import SuspiciousError from "./pages/SuspiciousError";

export default function App() {
  const NavigateToLiima = () => {
    window.location.href = process.env.REACT_APP_CUSTOMER_APP_URL;
    return <></>;
  };

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" exact element={<NavigateToLiima />} />
        <Route path="/:id" element={<HomePage />} />
        <Route path="/:company_slug/:id" element={<HomePage />} />
        <Route path="/suspicious" element={<SuspiciousError />} />
        <Route path="*" element={<Notfound />} />
      </Routes>
    </BrowserRouter>
  );
}
